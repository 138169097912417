import React, {Component} from 'react';
import Spinner from '../../components/UI/Spinner/Spinner'
import certifySquirrelSuper from '../../assets/images/certify_squirrel_super.png';
import {connect} from 'react-redux'
import * as actions from '../../store/index';
import {PDFDownloadLink} from '@react-pdf/renderer';
import FeeAcceptance from '../PdfGenerator/FeeAcceptance'

import {AcceptFessCheck, AcceptFessForm, OkButton} from './SignUp.styles';

class AcceptFees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accept: false
        };
    }

    postDataHandler = () => {
        const data = {
            accept_terms: 'Y',
        };

        this.props.onSignupAcceptFees(
            this.props.signup_basic_information_response.uuid,
            data
        );
    }

    componentDidUpdate() {
        if (this.props.signup_accept_fees_success)
            this.props.history.push({pathname: '/be_in_touch/'})
    }

    render() {
        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p>{this.props.signup_basic_information_response}</p>
            );
        }

        let full_name = ''
        if (this.props.signup_basic_information != null){
            if(this.props.signup_basic_information.first_name != null)
                full_name+=this.props.signup_basic_information.first_name;
            if(this.props.signup_basic_information.middle_name != null)
                full_name+= ' ' + this.props.signup_basic_information.middle_name;
            if(this.props.signup_basic_information.last_name != null)
                full_name+= ' ' + this.props.signup_basic_information.last_name;
        }

        return (
            <>
            <Spinner show={this.props.loading}/>
            <AcceptFessForm>
                {errorMessage}
                <h1>Your last step</h1>
                <h3>Please read carefully</h3>
                <p><img src={certifySquirrelSuper} alt="Squirrel"/></p>
                <AcceptFessCheck>
                    <input type="checkbox" value={this.state.accept} onChange={() => this.setState({accept: !this.state.accept})}/>

                    <label>By clicking the Agree button below. I/we accept to be bound by the Squirrel Superannuation Pty Ltd:

                        &nbsp;<a target="_blank" rel="noopener noreferrer" href="https://www.squirrelsuper.com.au/terms-and-conditions.html"> Terms and Conditions</a>&nbsp;

                        Establishment and annual administrative &nbsp;

                        <PDFDownloadLink document={<FeeAcceptance full_name={full_name}/>}
                                         fileName="fee_acceptance.pdf">
                            {({blob, url, loading, error}) => (loading ? 'Loading document...' : 'Fee Arrangement')}
                        </PDFDownloadLink>

                        &nbsp; <a target="_blank" rel="noopener noreferrer" href="https://www.squirrelsuper.com.au/privacy-policy.html">Privacy Policy</a>
                    </label>

                </AcceptFessCheck>
                <OkButton onClick={this.postDataHandler} disabled={!this.state.accept}>Agree</OkButton>
            </AcceptFessForm>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        signup_basic_information: state.signup.signup_basic_information,
        signup_accept_fees: state.signup.signup_accept_fees,
        signup_accept_fees_success: state.signup.signup_accept_fees_success,
        signup_accept_fees_response: state.signup.signup_accept_fees_response,
        signup_basic_information_response: state.signup.signup_basic_information_response,
        loading: state.signup.loading,
        error: state.signup.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignupAcceptFees: (uuid, accept_fees) => dispatch(actions.signupAcceptFees(uuid, accept_fees))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptFees);
