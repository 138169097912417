import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import auth from './containers/Auth/reducer';
import signup from './containers/SignUp/reducer';
import customerDashboard from './containers/Admin/Customer/reducer';
import staffDashboard from './containers/Admin/Staff/reducer';
import {StateLoader} from "./store/utility"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const stateLoader = new StateLoader();

const rootReducer = combineReducers({
    auth: auth,
    signup: signup,
    customerDashboard: customerDashboard,
    staffDashboard: staffDashboard
});

const store = createStore(rootReducer, stateLoader.loadState(), composeEnhancers(
    applyMiddleware(thunk)
));

store.subscribe(() => {
    stateLoader.saveState(store.getState());
});

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
