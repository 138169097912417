import React, { Component } from "react";
import { connect } from "react-redux";
import NavigationItemsUl from "./NavigationItems.styles"
import NavigationItem from "./NavigationItem/NavigationItem";


class NavigationItems extends Component {
  render() {

      let logout = <NavigationItem link="/logout">Logout</NavigationItem>;
      let login_url = null;
      let link_name = null;


      if (this.props.user_details && this.props.user_details.staff_member_id){
          login_url = '/staff_dashboard/';
          link_name = 'Admin';
      }else if (this.props.user_details && this.props.user_details.smsf_member_id){
          login_url = '/customer_dashboard/';
          link_name = 'Admin';
      }else{
          login_url = '/auth/'
          link_name = 'Login';
          logout = null;
      }


    return (
      <NavigationItemsUl>
        <NavigationItem link="/basic_information" exact>SignUp</NavigationItem>
        <NavigationItem link={login_url}>{link_name}</NavigationItem>
        {logout}
      </NavigationItemsUl>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user_details: state.auth.user_details
  };
};

export default connect(mapStateToProps)(NavigationItems);
