import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: { fontFamily: 'Helvetica', backgroundColor: 'white', paddingVertical: 50, paddingHorizontal: 30},
    section: { textAlign: 'left', margin: 30 },    
    title: {fontSize: 18, marginBottom: 20, fontFamily: 'Helvetica-Bold'},
    title2: {fontSize: 14, marginBottom: 20, fontFamily: 'Helvetica-Bold'},
    titleSub: {fontSize: 14, marginBottom: 20, textDecoration: 'underline', fontFamily: 'Helvetica-Bold'},
    sub: {fontSize: 12, marginVertical: 20, fontFamily: 'Helvetica-Bold'},
    list: {fontSize: 10, marginBottom: 5 },
    listBold: {fontSize: 10, marginBottom: 5, fontFamily: 'Helvetica-Bold'},
    text: {fontSize: 10, marginBottom: 5 },
    date: {fontSize: 10, textAlign: 'right', marginTop: 20 },
    add: {fontSize: 8, marginBottom: 40, fontFamily: 'Helvetica-Oblique' },
    add2: {fontSize: 8, marginTop: 20, marginBottom: 40, fontFamily: 'Helvetica-Oblique' }
});

var monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
];
var dayOfWeekNames = [
    "Sunday", "Monday", "Tuesday",
    "Wednesday", "Thursday", "Friday", "Saturday"
]

function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return  dayOfWeekNames[date.getDay()]+ " " + date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear() + "  " + strTime;
}

let formatted_date = formatDate(new Date());


// Create Document Component
const feeAcceptance = props => (
    <Document>
        <Page size="A4" style={styles.page}>            
            <View style={styles.section}>            
                <Text style={styles.title}>
                    Establishment and annual administration fee agreement
                </Text>
                <Text style={styles.titleSub}>
                    Summary of fees
                </Text>
                <Text style={styles.title2}>
                    Establishment of Self Managed Super Fund, inclusive of:
                </Text>
                
                <Text style={styles.list}>-Establishment and registration of SMSF Trust</Text>
                <Text style={styles.list}>-Establishment and registration of Special Purpose Corporate Trustee Company</Text>
                <Text style={styles.list}>-Establishment of SMSF governing rules</Text>
                <Text style={styles.list}>-Establishment and registration of investment strategy</Text>
                <Text style={styles.list}>-Submission of “Election to be regulated” Compliance statement</Text>
                <Text style={styles.list}>-Establishment of accounts with service providers</Text>
            
                <Text style={styles.sub}>
                    X Fixed Fee $3,171.05 (inc GST)
                </Text>
                <Text style={styles.add}>
                    To assist clients, Squirrel funds all upfront establishment costs including payments to regulatory bodies including ASIC, and the ATO, and then it recovers these costs from the SMSF cash account once the rollover of external super fund/s has been completed, or from you if the rollover does not take please within eight (8) weeks from your establishment of the SMSF.
                </Text>
                <Text style={styles.title2}>
                    Additional Services at no cost to you, inclusive of:
                </Text>
                
                <Text style={styles.listBold}>-Registration with Australian Tax Office </Text>
                <Text style={styles.listBold}>-Rollover of other superannuation funds into SMSF</Text>
                
                <Text style={styles.add2}>
                    You agree to do all things and sign all documents necessary to assist in the facilitation of the registration of your SMSF with the Australian Tax Office and the rollover of other superannuation funds into your SMSF. This includes completing all necessary documents and forms for the registration of the SMSF with the ATO within a reasonable period of time but no more than fourteen (14) days after the establishment of your SMSF, and to arrange for and attend at an interview with the ATO if and when requested.
                </Text>
                <Text style={styles.title2}>
                    Annual Administration Fee, inclusive of:
                </Text>
                
                <Text style={styles.list}>-Annual compliance, administration and maintenance of SMSF</Text>
                <Text style={styles.list}>-Annual return of Special Purpose Company</Text>
                <Text style={styles.list}>-24/7 access to Squirrel online SMSF management portal</Text>
                <Text style={styles.list}>-Business hour access to Squirrel technical experts</Text>
                <Text style={styles.list}>-Fixed fee for 1 – 4 members and any number / value of assets</Text>                
                
                <Text style={styles.sub}>
                    Fixed Fee $121.00 per month (inc GST) deducted from SMSF cash account
                </Text>
                <Text style={styles.text}>
                    I/We hereby authorise Squirrel Superannuation Services Pty Ltd ACN 169 366 750 AFSL 462160 (herein referred to as ‘Squirrel’) to make periodic debits as indicated on this ‘Establishment and annual administration fee agreement’.
                </Text>
                <Text style={styles.text}>
                    Fees for all Squirrel services are paid by direct debit from the main cash operating account of the SMSF once it has been established. We may also use the account to make other required payments, such as tax payments on your behalf. Administration fees are generally payable monthly in arrears. The minimum term for the Squirrel service is 12 months. If you choose to transfer your Fund to another administrator or otherwise cancel your arrangement with Squirrel within 12 months we may charge you an administration fee equal to the remainder of 12 months administration fee. We will generally charge this fee at the point you notify us that you are leaving the service. If for whatever reason, your Fund does not have sufficient funds to pay any fees owing to Squirrel, then you agree to be liable to pay for those fees. All fees are in Australian dollars and are exclusive of GST where applicable.
                </Text>
                <Text style={styles.sub}>
                    Fee Authority
                </Text>
                <Text style={styles.text}>
                    I {props.full_name} as a proposed public officer and Director of the Corporate Trustee authorise the payment of establishment and administration fees to Squirrel Superannuation Services Pty Ltd AFSL 462160 and Tax Practitioner Board Member 24864562. I acknowledge and agree that where there are insufficient funds in the SMSF after eight (8) weeks from the establishment of the SMSF, then you agree to make payment of any fees which are due and owing to Squirrel.
                </Text>
                <Text style={styles.date}>
                    Date: {props.accept_terms_timestamp ? props.accept_terms_timestamp : formatted_date}
                </Text>

            </View>
        </Page>
    </Document>
);

export default feeAcceptance;