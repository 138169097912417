export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const FUD_START = 'FETCH_USER_DETAILS_START';
export const FUD_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FUD_FAIL = 'FETCH_USER_DETAILS_FAIL';

export const SBI_START = 'SIGNUP_BASIC_INFORMATION_START';
export const SBI_SUCCESS = 'SIGNUP_BASIC_INFORMATION_SUCCESS';
export const SBI_FAIL = 'SIGNUP_BASIC_INFORMATION_FAIL';

export const SPI_START = 'SIGNUP_PERSONAL_INFORMATION_START';
export const SPI_SUCCESS = 'SIGNUP_PERSONAL_INFORMATION_SUCCESS';
export const SPI_FAIL = 'SIGNUP_PERSONAL_INFORMATION_FAIL';

export const SFI_START = 'SIGNUP_FUND_INFORMATION_START';
export const SFI_SUCCESS = 'SIGNUP_FUND_INFORMATION_SUCCESS';
export const SFI_FAIL = 'SIGNUP_FUND_INFORMATION_FAIL';

export const SAF_START = 'SIGNUP_ACCEPT_FEES_START';
export const SAF_SUCCESS = 'SIGNUP_ACCEPT_FEES_SUCCESS';
export const SAF_FAIL = 'SIGNUP_ACCEPT_FEES_FAIL';

export const CDFM_START = 'CUSTOMER_DASHBOARD_FETCH_MEMBER_START';
export const CDFM_SUCCESS = 'CUSTOMER_DASHBOARD_FETCH_MEMBER_SUCCESS';
export const CDFM_FAIL = 'CUSTOMER_DASHBOARD_FETCH_MEMBER_FAIL';

export const CDSMD_START = 'CUSTOMER_DASHBOARD_SAVE_MEMBER_DETAILS_START';
export const CDSMD_SUCCESS = 'CUSTOMER_DASHBOARD_SAVE_MEMBER_DETAILS_SUCCESS';
export const CDSMD_FAIL = 'CUSTOMER_DASHBOARD_SAVE_MEMBER_DETAILS_FAIL';

export const FETCH_SMSF_MEMBER_START = 'FETCH_SMSF_MEMBER_START';
export const FETCH_SMSF_MEMBER_SUCCESS = 'FETCH_SMSF_MEMBER_SUCCESS';
export const FETCH_SMSF_MEMBER_FAIL = 'FETCH_SMSF_MEMBER_FAIL';

export const SEARCH_SMSF_MEMBER_START = 'SEARCH_SMSF_MEMBER_START';
export const SEARCH_SMSF_MEMBER_SUCCESS = 'SEARCH_SMSF_MEMBER_SUCCESS';
export const SEARCH_SMSF_MEMBER_FAIL = 'SEARCH_SMSF_MEMBER_FAIL';

export const SELECT_SMSF_MEMBER_START = 'SELECT_SMSF_MEMBER_START_START';
export const SELECT_SMSF_MEMBER_SUCCESS = 'SELECT_SMSF_MEMBER_SUCCESS';
export const SELECT_SMSF_MEMBER_FAIL = 'SELECT_SMSF_MEMBER_FAIL';

export const SAVE_SMSF_MEMBER_START = 'SAVE_SMSF_MEMBER_START';
export const SAVE_SMSF_MEMBER_SUCCESS = 'SAVE_SMSF_MEMBER_SUCCESS';
export const SAVE_SMSF_MEMBER_FAIL = 'SAVE_SMSF_MEMBER_FAIL';


