import axios from 'axios';

const instance = axios.create({
    //baseURL: 'http://smsfapi.squirrelsuper.com.au'
    baseURL: 'https://api.squirrelsuper.com.au'
    //baseURL: 'http://localhost:8000'
});

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default instance;