import styled from "styled-components";

const StaffDashboardStyled = styled.div`
    background: #f8f5f4;
    padding: 30px 0;
    border-radius: 3px;
    text-align: center;
    width: 100%;
`

const UserList = styled.ul`
  list-style: none;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  padding: 0;
  background: white;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    max-width: 85vw;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  li {
    display: flex;
    align-items: flex-start;
    padding: 14px 7px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: flex-end;
    transition: background 0.3s;
    font-size: 16px;
    cursor: pointer;

    @media (max-width: 700px) {
      min-width: 700px;
    }

    &:hover {
      background-color: #eeeeee;
    }

    &:first-child {
      min-height: 30px;
      padding: 7px;
      align-items: flex-end;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      font-weight: bold;
      font-size: 14px;

      span {
        cursor: pointer;
      }
    }
  }

  li span {
    width: 100%;
    max-width: 200px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100px;

    &:first-child {
      min-width: 20px;
      max-width: 50px;
    }

    &:last-child {
      display: flex;
      justify-content: center;
    }
  }

  li span button {
    background: #ff3f3f;
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 2px;
    color: white;
    padding: 5px 10px;
  }
`;

export { UserList, StaffDashboardStyled };
