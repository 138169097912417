import React, {Component} from "react";
import {connect} from "react-redux";

import Aux from "../../../hoc/Aux/Aux";

import * as actions from "../../../store/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Input from "../../../components/UI/Input/Input";

import MemberDetails from "../Customer/MemberDetails";
import { UserList, StaffDashboardStyled } from "./styles";

class CustomerDashboard extends Component {
  state = {
    memberId: null
  };

  backRoute = () => this.props.history.push(`/staff_dashboard`);

  goToMemberDetails = id => this.props.history.push(`/staff_dashboard/${id}`);

  notSmsfMembers = () =>
    !this.props.smsf_members
      ? this.props.onFetchSmsfMembers(this.props.token)
      : "";
      
  getMemberIdParam = () =>
    this.props.location.pathname.split("/")[2]
      ? this.setState({ memberId: this.getMemberIdParam })
      : "";

  componentDidMount() {
    this.notSmsfMembers();
    this.getMemberIdParam();
  }

  postDataHandler = () => {
    const data = {
      // username: this.state.controls.email.value,
      // email: this.state.controls.email.value,
      // first_name: this.state.controls.first_name.value,
      // middle_name: this.state.controls.middle_name.value,
      // last_name: this.state.controls.last_name.value,
      // mobile_number: this.state.controls.mobile_number.value,
      // sfunds: [{balance: this.state.controls.fund_balance.value}]
    };

    this.props.onSaveSmsfMember(data);
  };

  onSelectMember = async member => {
    this.props.onSelectedSmsfMember(member.id, this.props.token).then(() => {
      this.goToMemberDetails(member.id);
      this.setState({ memberId: member.id });
    });
  };

  handleCancel() {
    this.setState({ memberId: null }, this.backRoute);
  }

  memberDetails = () => (
    <MemberDetails
      key={this.props.selected_smsf_member.id}
      smsf_member_details={this.props.selected_smsf_member}
      back={this.handleCancel.bind(this)}
    />
  );

  smsfMembers = () =>
    this.props.smsf_members.map(member => {
      return (
        <li key={member.id} onClick={() => this.onSelectMember(member)}>
          <span>{member.id}</span>
          <span>{member.first_name}</span>
          <span>{member.last_name}</span>
          <span>{member.email}</span>
          <span>{member.mobile_number}</span>
          <span>{member.tax_file_number}</span>
          <span>{member.updated.split('T')[0]}</span>
        </li>
      );
    });

  getContent() {
    if (this.state.memberId) return this.memberDetails();

    let smsfMembers =
      this.props.smsf_members != null ? this.smsfMembers() : null;

    return (
      <StaffDashboardStyled>
        <div>
          <Input
            aria-label="teste"
            key="1"
            elementType="input"
            elementConfig={{ 
              type: "text",
              placeholder: "Search",
              onBlur: (event) => { 
                this.props.onSearchSmsfMembers(
                  event.target.value,
                  this.props.token
                )
              }
            }}
          />
        </div>
        <UserList>
          <li>
            <span>#</span>
            <span>First name</span>
            <span>Last name</span>
            <span>Email</span>
            <span>Mobile number</span>
            <span>Tax file number</span>
            <span>Updated</span>
          </li>
          {smsfMembers}
        </UserList>
      </StaffDashboardStyled>
    );
  }

  render() {
    return (
      <Aux>
        <Spinner show={this.props.loading} />
        <div>{this.getContent()}</div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    smsf_members: state.staffDashboard.smsf_members,
    search_parameters: state.staffDashboard.search_parameters,
    selected_smsf_member: state.staffDashboard.selected_smsf_member,
    show_selected_smsf_member: state.staffDashboard.show_selected_smsf_member,
    loading: state.staffDashboard.loading,
    error: state.staffDashboard.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchSmsfMembers: token => dispatch(actions.fetchSmsfMembers(token)),
    onSearchSmsfMembers: (event, token) => dispatch(actions.searchSmsfMembers(event, token)),
    onSelectedSmsfMember: (smsf_member_id, token) => dispatch(actions.selectSmsfMember(smsf_member_id, token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDashboard);
