import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/index";
import {PDFDownloadLink} from '@react-pdf/renderer';
import FeeAcceptance from '../../PdfGenerator/FeeAcceptance'
import { OkButton, CancelButton } from "../../SignUp/SignUp.styles";
import {
    Tabs,
    ListFunds,
    Wrapper,
    ButtonTab,
    Details,
    AddButton,
    DocStyled
} from "./styles";

import CurrencyInput from "react-currency-input";

const JSON_INFOS_HELPER = {
    basic: {
        email: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile_number: "",
        mothers_maiden_name: ""
    },
    personal: {
        residential_address: "",
        gender: "",
        date_of_birth: "",
        city_and_country_of_birth: "",
        employer: "",
        occupation: "",
        annual_income: "",
        investment_strategies: "",
        tax_file_number: "",
        lead_status: ""
    },
    fund: {
        name: "",
        balance: ""
    },
    doc: {
        accept_terms: "",
        accept_terms_timestamp: ""
    }
};

const LEAD_STATUS = {
    1: `New lead`,
    2: `Contact attempted`,
    3: `In progress`,
    4: `Converted`,
    5: `Abandoned`
};

const ROLLOVER_FORMAT = {
    F: `Full`,
    P: `Partial`
};

class MemberDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            fields: {
                name: "",
                email: "",
                sfunds: "",
                gender: "",
                balance: "",
                employer: "",
                last_name: "",
                occupation: "",
                first_name: "",
                lead_status: "",
                middle_name: "",
                accept_terms: "",
                annual_income: "",
                mobile_number: "",
                date_of_birth: "",
                tax_file_number: "",
                residential_address: "",
                mothers_maiden_name: "",
                investment_strategies: "",
                accept_terms_timestamp: "",
                city_and_country_of_birth: ""
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        event.persist();
        this.setState(prevState => ({
            fields: {
                ...prevState.fields,
                [event.target && event.target.name && event.target.name]:
                (event.target && event.target.value) || ""
            }
        }));
    }

    handleBalanceChange(e, v) {
      this.setState(prevState => ({
        fields: {
          ...prevState.fields,
          [e]: v
        }
      }));
    }

    componentDidMount() {
        if (this.props.smsf_member_details) {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    email: this.props.smsf_member_details.email || "",
                    first_name: this.props.smsf_member_details.first_name || "",
                    middle_name: this.props.smsf_member_details.middle_name || "",
                    last_name: this.props.smsf_member_details.last_name || "",
                    mobile_number: this.props.smsf_member_details.mobile_number || "",
                    residential_address:
                        this.props.smsf_member_details.place_of_residence == null ? "" : (this.props.smsf_member_details.place_of_residence.street_name || ""),
                    gender: this.props.smsf_member_details.gender || "",
                    date_of_birth: this.props.smsf_member_details.birth_date || "",
                    city_and_country_of_birth:
                        this.props.smsf_member_details.place_of_birth == null ? "" : (this.props.smsf_member_details.place_of_birth.street_name || ""),
                    mothers_maiden_name:
                    this.props.smsf_member_details.mothers_maiden_name || "",
                    employer: this.props.smsf_member_details.employer || "",
                    occupation: this.props.smsf_member_details.occupation || "",
                    annual_income: this.props.smsf_member_details.annual_income || "",
                    tax_file_number: this.props.smsf_member_details.tax_file_number || "",
                    lead_status: this.props.smsf_member_details.lead_status || "",
                    sfunds: this.props.smsf_member_details.sfunds || "",
                    name:
                    (this.props.smsf_member_details.smsfund &&
                        this.props.smsf_member_details.smsfund.name) ||
                    "",
                    balance:
                    (this.props.smsf_member_details.smsfund &&
                        this.props.smsf_member_details.smsfund.balance) ||
                    "",
                    investment_strategies:
                    this.props.smsf_member_details.investment_strategies || "",
                    accept_terms: this.props.smsf_member_details.accept_terms || "",
                    accept_terms_timestamp:
                    this.props.smsf_member_details.accept_terms_timestamp || ""
                }
            }));
        }
    }

    postDataHandler = () => {
        const data = {
            email: this.state.fields.email,
            first_name: this.state.fields.first_name,
            middle_name: this.state.fields.middle_name,
            last_name: this.state.fields.last_name,
            mobile_number: this.state.fields.mobile_number,

            place_of_residence: {street_name: this.state.fields.residential_address},
            place_of_birth: {street_name: this.state.fields.city_and_country_of_birth},

            gender: this.state.fields.gender,
            birth_date: this.state.fields.date_of_birth,

            mothers_maiden_name: this.state.fields.mothers_maiden_name,

            tax_file_number: this.state.fields.tax_file_number,
            employer: this.state.fields.employer,
            occupation: this.state.fields.occupation,
            annual_income: this.state.fields.annual_income.replace('$','').replace(',',''),

            smsfund: {
                id: this.props.smsf_member_details.smsfund != null ? this.props.smsf_member_details.smsfund['id'] : 0,
                balance: this.state.fields.balance.replace('$','').replace(',',''),
                name: this.state.fields.name,
                investment_strategies: [{name: this.state.fields.investment_strategies}]
            },

            lead_status: this.state.fields.lead_status,
        };

        console.log(data);

        this.props.onSaveMemberDetails(
            this.props.token,
            this.props.smsf_member_details.id,
            data
        );
    };

    getTypeInput(value) {
        if (value.includes("number")) return "number";

        if (value.includes("income")) return "number";

        if (value.includes("balance")) return "number";

        if (value.includes("date")) return "date";

        if (value.includes("email")) return "email";

        return "text";
    }

    getInputs(data) {
        return Object.keys(data).map(key => {
            if (key === "lead_status") {
                return (
                    <div className="fieldset" key={key}>
                        <label>{key.split("_").join(" ")}</label>
                        <select
                            key="lead_status"
                            name="lead_Status"
                            value={this.state.fields.lead_status}
                            onChange={this.handleInputChange}
                        >
                            <option disabled value="X">
                                Choose one
                            </option>
                            {Object.values(LEAD_STATUS).map((status, key) => (
                                <option key={key} value={status.key}>
                                    {status}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            }

            if (key === "gender") {
                return (
                    <div className="fieldset" key={key}>
                        <label>{key.split("_").join(" ")}</label>
                        <select
                            key="gender"
                            name="gender"
                            value={this.state.fields.gender}
                            onChange={this.handleInputChange}
                        >
                            <option disabled value="X">
                                Choose one
                            </option>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                        </select>
                    </div>
                );
            }

            if (key === "investment_strategies") {
                return (
                    <div className="fieldset" key={key}>
                        <label>{key.split("_").join(" ")}</label>
                        <select
                            key="investment_strategies"
                            name="investment_strategies"
                            value={this.state.fields.investment_strategies}
                            onChange={this.handleInputChange}
                        >
                            <option value="residential property">Residential property</option>
                            <option value="australian shares">Australian shares</option>
                            <option value="international shares">International shares</option>
                            <option value="term deposits and fixed interest">
                                Term deposits and fixed interest
                            </option>
                            <option value="cryptocurrencies">Cryptocurrencies</option>
                            <option value="commercial property">Commercial property</option>
                            <option value="collectibles">Collectibles</option>
                            <option value="managed funds">Managed funds</option>
                            <option value="cash">Cash</option>
                            <option value="precious metal">Precious metal</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                );
            }

            return (
                <div className="fieldset" key={key}>
                    <label>{key.split("_").join(" ")}</label>

                    {key.includes("income") || key.includes("balance") ? (
                        <CurrencyInput
                            name={key}
                            prefix="$"
                            value={this.state.fields[key]}
                            onChange={this.handleBalanceChange.bind(this, key)}
                        />
                    ) : (
                        <input
                            type={this.getTypeInput(key)}
                            name={key}
                            value={this.state.fields[key] || ""}
                            onChange={this.handleInputChange}
                        />
                    )}
                </div>
            );
        });
    }

    render() {

        let full_name = ''
        if (this.props.smsf_member_details != null){
            if(this.props.smsf_member_details.first_name != null)
                full_name+=this.props.smsf_member_details.first_name;
            if(this.props.smsf_member_details.middle_name != null)
                full_name+= ' ' + this.props.smsf_member_details.middle_name;
            if(this.props.smsf_member_details.last_name != null)
                full_name+= ' ' + this.props.smsf_member_details.last_name;
        }

        return (
            <>
            <Tabs>
                <ButtonTab
                    onClick={() => this.setState({ tab: 1 })}
                    active={this.state.tab === 1}
                    value="Basic"
                >
                    Basic
                </ButtonTab>

                <ButtonTab
                    onClick={() => this.setState({ tab: 2 })}
                    active={this.state.tab === 2}
                    value="Personal"
                >
                    Personal
                </ButtonTab>

                <ButtonTab
                    onClick={() => this.setState({ tab: 3 })}
                    active={this.state.tab === 3}
                    value="Fund"
                >
                    Fund
                </ButtonTab>

                <ButtonTab
                    onClick={() => this.setState({ tab: 4 })}
                    active={this.state.tab === 4}
                    value="Doc"
                >
                    Doc
                </ButtonTab>
            </Tabs>
            <Details>
                {this.state.tab === 1 ? this.getInputs(JSON_INFOS_HELPER.basic) : ""}

                {this.state.tab === 2
                    ? this.getInputs(JSON_INFOS_HELPER.personal)
                    : ""}

                {this.state.tab === 3 ? (
                    <>
                    <Wrapper>{this.getInputs(JSON_INFOS_HELPER.fund)}</Wrapper>

                    <Wrapper>
                        <h3>Your super funds</h3>
                        <ListFunds>
                            <li>
                                <span>Acc. Number</span>
                                <span>Balance</span>
                                <span>Rollover</span>
                                <span>Actions</span>
                            </li>
                            {this.state.fields.sfunds &&
                            this.state.fields.sfunds.map((fund, i) => {
                                return (
                                    <li key={fund.uuid}>
                                        <span>{fund.account_number}</span>
                                        <span>
                            {parseInt(fund.balance).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                            })}
                          </span>
                                        <span>{ROLLOVER_FORMAT[fund.rollover]}</span>
                                        <span>
                            <button>-</button>
                          </span>
                                    </li>
                                );
                            })}
                            <AddButton>+</AddButton>
                        </ListFunds>
                    </Wrapper>
                    </>
                ) : (
                    ""
                )}

                {this.state.tab === 4 ? (
                    <DocStyled>
                        {this.state.fields.accept_terms ? (
                            <>
                            <PDFDownloadLink document={<FeeAcceptance accept_terms_timestamp={this.state.fields.accept_terms_timestamp.split("T")[0]} full_name={full_name}/>}
                                             fileName="fee_acceptance.pdf">
                                {({blob, url, loading, error}) => (loading ? 'Loading document...' : 'this is your fee acceptance')}
                            </PDFDownloadLink>

                            <h3>{`You accepted in ${
                                this.state.fields.accept_terms_timestamp.split("T")[0]
                                } ${this.state.fields.accept_terms_timestamp.split("T")[1].split(".")[0]}` }</h3>
                            </>
                        ) : (
                            <h3>You have not yet accepted fee</h3>
                        )}
                    </DocStyled>
                ) : (
                    ""
                )}
            </Details>
            <OkButton onClick={this.postDataHandler}>Update</OkButton>
            <CancelButton onClick={this.props.back}>Cancel</CancelButton>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        loading: state.customerDashboard.loading,
        error: state.customerDashboard.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSaveMemberDetails: (token, id, member_details) => dispatch(actions.saveMemberDetails(token, id, member_details))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberDetails);
